<script>
import Layout from "../../layouts/no-menu/main-no-menu";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { orderMethods, orderComputed } from "@/state/helpers";
import trackOrder from "@/components/ext/track-order.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Track Order",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, trackOrder },
    computed: {
        ...orderComputed,
    },
    data() {
        return {
            title: "Track Order",
            items: [],
            failed_to_load: false,
            is_loading: true,
        };
    },
    methods: {
        ...orderMethods,
    },
    mounted() {
        this.getTokenOrderDetails(this.$route.params.token)
            .then(() => {
                this.is_loading = false;
            })
            .catch(() => {
                this.is_loading = false;
                this.failed_to_load = true;
            });
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <b-alert :show="is_loading && failed_to_load == false" variant="info">
            Loading order details.
        </b-alert>
        <b-alert :show="failed_to_load" variant="danger">
            Failed to load order details.
            <li>Order token has expired or is invalid</li>
        </b-alert>

        <trackOrder
            v-if="ext_order != null && failed_to_load == false"
            :order_data="ext_order"
            :read_only="true"
        />

        <b-overlay :show="is_loading" style="height: 300px"></b-overlay>
    </Layout>
</template>
